.App {
  display: grid;
  grid-template-columns: 75% auto;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey !important;
  border-radius: 50px !important;
}

.columnmodal-table .editable-cell-value-wrap {
  cursor: pointer;
}

.column-modal-container {
  top: 50px !important;
}

.auth-section-left {
  height: 100%;
  padding: 24px;
  justify-content: center;
}

.auth-section-right {
  padding: 24px;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.auth-container {
  box-shadow: rgb(60 64 67 / 15%) 0px 48px 100px 0px;
  width: 580px;
  border-radius: 12px !important;
  margin-bottom: 20px;
}

.auth-container .ant-card-head-title {
  padding-bottom: 0px !important;
}

.auth-container
  .ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon,
.auth-container
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #f04f14;
}

.auth-container .ant-steps-navigation .ant-steps-item::before {
  background-color: #f04f14;
}